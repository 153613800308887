import { InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { registerComponents } from '@/builder';
import Loading from '@/components/loading';
import PageModel from '@/models/PageModel';
import Page from '@/modules/pages/page';
import { HOME_PAGE_NAME } from '@/shared/constant';

registerComponents();

export async function getStaticProps() {
    const page = await PageModel.findPage({ params: { url: HOME_PAGE_NAME } });

    return {
        props: { page },
        revalidate: 5,
    };
}

const Home: FC<InferGetStaticPropsType<typeof getStaticProps>> = ({ page }) => {
    const router = useRouter();

    if (router.isFallback) return <Loading />;

    if (!page) return null;

    return <Page page={page} />;
};

export default Home;
