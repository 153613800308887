import { BuilderComponent, BuilderContent } from '@builder.io/react';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useEffect } from 'react';

import MetaBlock from '@/components/meta-block/MetaBlock';
import NotFound from '@/components/not-found/not-found';
import { ThemeStyles, useThemeContext } from '@/contexts/ThemeContext';
import PageModel from '@/models/PageModel';
import { BUILDER_MODEL } from '@/types/builder.d';
import { getBlockDataMap } from '@/utils/Builder';

export async function getStaticProps({ params }: GetStaticPropsContext<any>) {
    const slug = params.slug;
    const pageSlug = Array.isArray(slug) ? slug.join('/') : slug;
    const page = await PageModel.findPage({ params: { url: pageSlug }, includeUnpublished: true });

    if (!page) {
        return <NotFound />;
    }

    return {
        props: { page },
        revalidate: 5,
    };
}

export async function getStaticPaths() {
    const pages = await PageModel.getAllPage(false, false, 'data.url');
    const paths = pages.map((page: any) => PageModel.convertBuilderURL2Slug(page.data.url));
    return {
        paths,
        fallback: true,
    };
}

export const getPageById = async (pageId?: string | string[], cb?: (page: any) => void) => {
    if (!pageId) return;
    const page = await PageModel.findPage({
        params: { modelId: Array.isArray(pageId) ? pageId[0] : pageId },
    });
    cb?.(page);
    return page;
};

export default function Page(
    props: InferGetStaticPropsType<typeof getStaticProps> & {
        builderContent: BuilderContent;
    }
) {
    const { page } = props;
    const theme = useThemeContext();

    const renderContent = () => <BuilderComponent model={BUILDER_MODEL.PAGE} content={page} data={getBlockDataMap(page)} />;

    useEffect(() => {
        theme.setThemeStyle(page.data.layout);
        if (page.data.layout === ThemeStyles.Blog && page.data.theme) {
            theme.setArticleTheme(page.data.theme);
        } else {
            theme.setArticleTheme(null);
        }

        if (page.data.hideDefaultFooter) {
            theme.setShowBookDemoFooter(false);
        }

        return () => {
            theme.setArticleTheme(null);
            theme.setShowBookDemoFooter(true);
        };
    }, [page, theme]);

    return (
        <>
            <MetaBlock title={page?.data?.title} description={page?.data?.description} />
            {renderContent()}
        </>
    );
}
